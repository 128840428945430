import React from "react"
import styled from "styled-components"

const Section = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
`

export default ({ children }) => (
  <div>
    {React.Children.map(children, child => {
      let id
      if (child.props && child.props.id) {
        id = child.props.id
      }

      return <Section id={id}>{child}</Section>
    })}
  </div>
)
