import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const examples = [
  {
    name: "App 1: Timer",
    link:
      "https://learn.reactnativeschool.com/courses/555707/lectures/10077031",
    points: [
      "How to start a new project",
      "Building a UI from core components & styling them",
      "Differentiating between different platforms",
      "Capturing user input",
    ],
    image: "Timer.png",
  },
  {
    name: "App 2: Calculator",
    link:
      "https://learn.reactnativeschool.com/courses/555707/lectures/10077022",
    points: [
      "More complex layout with flexbox",
      "Project organization",
      "Building and using custom components",
      "Handling complex state",
    ],
    image: "Calculator.png",
  },
  {
    name: "App 3: Quiz",
    link:
      "https://learn.reactnativeschool.com/courses/555707/lectures/10077013",
    points: [
      "Navigation & building multi-screen apps",
      "Theming",
      "Managing and interacting with multiple data sources",
    ],
    image: "Quiz.png",
  },
  {
    name: "App 4: Weather",
    link:
      "https://learn.reactnativeschool.com/courses/555707/lectures/10469409",
    points: [
      "Working with third party APIs via the fetch API",
      "Working with external APIs",
      "Persisting data between sessions with the AsyncStorage API",
    ],
    image: "Weather.png",
  },
  {
    name: "App 5: Geocache",
    link:
      "https://learn.reactnativeschool.com/courses/555707/lectures/10730306",
    points: [
      "Set up and interact with an API",
      "Model a database to persist shared data",
      "Using maps in your app",
    ],
    image: "Geocache.png",
  },
  {
    name: "App 6: Review",
    link:
      "https://learn.reactnativeschool.com/courses/555707/lectures/11076486",
    points: [
      "Protecting API routes",
      "Authenticate with an API",
      "Manage and interact with an API that requires authentication",
    ],
    image: "Review.png",
  },
  {
    name: "App 7: Basic Game",
    link:
      "https://learn.reactnativeschool.com/courses/react-native-by-example/lectures/11373624",
    points: [
      "Creating animations in React",
      "Advanced component state management",
    ],
    image: "Game.png",
  },
  {
    name: "App 8: Chat",
    link:
      "https://learn.reactnativeschool.com/courses/555707/lectures/11543211",
    points: [
      "Configure and use React Native Firebase Authentication",
      "Configure and use React Native Firebase Firestore",
      "How to model Data",
    ],
    image: "Chat.png",
  },
  {
    name: "App 9: News",
    link:
      "https://learn.reactnativeschool.com/courses/555707/lectures/11697512",
    points: [
      "Learn the basics of GraphQL",
      "Interact with a REST API with Apollo",
      "Create modals with the modal component from React Native",
    ],
    image: "News.png",
  },
  {
    name: "App 10: Social Media",
    link:
      "https://learn.reactnativeschool.com/courses/555707/lectures/11896574",
    points: [
      "How to configure an Apollo/GraphQL server",
      "GraphQL mutations",
      "Optimistic client-side updates",
    ],
    image: "SocialMedia.png",
  },
]

const Content = styled.div`
  flex-direction: ${props => (props.reverse ? "row" : "row-reverse")};
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  margin-bottom: 2.5rem !important;

  &:last-child {
    border-bottom: 0;
    margin-bottom: -0.75rem !important;
  }
`

export default () => {
  const res = useStaticQuery(
    graphql`
      query ExampleImages {
        allFile(filter: { sourceInstanceName: { eq: "images" } }) {
          nodes {
            relativePath
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    `
  )

  return (
    <section className="section">
      <div className="container">
        {examples.map((example, index) => {
          const mod = index % 2
          const image = res.allFile.nodes.find(
            i => i.relativePath === example.image
          )

          return (
            <React.Fragment>
              <div className="columns is-vcentered" key={example.name}>
                <div className="column content">
                  <h3 className="title is-3 has-text-centered">
                    {example.name}
                  </h3>
                </div>
              </div>
              <Content
                className="columns is-vcentered"
                key={example.link}
                reverse={mod === 0}
              >
                <div className="column" />
                <div className="column is-4 content">
                  <Img fluid={image.childImageSharp.fluid} alt={example.name} />
                </div>
                <div className="column is-4 content">
                  <div>
                    <p>
                      <b>In this series you'll learn:</b>
                    </p>
                    <ul>
                      {example.points.map(point => (
                        <li key={point}>
                          <p>{point}</p>
                        </li>
                      ))}
                    </ul>
                    <a
                      href={example.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Watch the Intro Video
                    </a>
                  </div>
                </div>
                <div className="column" />
              </Content>
            </React.Fragment>
          )
        })}
      </div>
    </section>
  )
}
