import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

// const Button = styled.button`
//   margin-bottom: 1rem;
// `

const Ahref = styled.a`
  margin-bottom: 1rem;
`

const onButtonPress = buyLink => {
  let finalUrl = buyLink
  // buy link already has one url param on it

  if (window && window.location && window.location.search && URLSearchParams) {
    const urlParams = new URLSearchParams(window.location.search)

    const couponCode = urlParams.get("coupon_code")
    if (couponCode) {
      finalUrl = `${finalUrl}&coupon_code=${couponCode}`
    }

    const affCode = urlParams.get("affcode")
    if (affCode) {
      finalUrl = `${finalUrl}&affcode=${affCode}`
    }
  }

  window.open(finalUrl)
}

export default () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            login_link
            buy_link
          }
        }
      }
    `
  )

  return (
    <section className="section is-medium">
      <div className="container has-text-centered content">
        {/* <h3 className="is-2 title">Instant Access to All 10 Examples</h3> */}
        <div className="columns">
          <div className="column is-one-fifth" />
          <div className="column">
            <h3 className="is-2 title">Enrollment Closed</h3>
            <p>
              Due to significant API changes I've closed enrollment to React
              Native by Example while I do a total rebuild of the course with
              the latest tools and APIs.
            </p>
            <p>
              That said, I don't want to leave you hanging. Check out React
              Native School for the constantly updated React Native content.
            </p>
            {/* <Button
          className="button is-primary is-rounded is-large"
          onClick={() => onButtonPress(site.siteMetadata.buy_link)}
        >
          Buy Now - $149
        </Button> */}
            <Ahref
              className="button is-primary is-rounded is-large"
              href="https://www.reactnativeschool.com"
            >
              React Native School
            </Ahref>
            <p>
              Already joined? <a href={site.siteMetadata.login_link}>Log in</a>{" "}
              to access the course!
            </p>
          </div>
          <div className="column is-one-fifth" />
        </div>
      </div>
    </section>
  )
}
