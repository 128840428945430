import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

export default () => {
  const { file } = useStaticQuery(
    graphql`
      query OverviewImage {
        file(relativePath: { eq: "lesson.png" }) {
          childImageSharp {
            fluid(maxWidth: 700, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  return (
    <section className="section">
      <div className="container">
        <div className="columns is-vcentered">
          <div className="column content">
            <h2 className="title is-2">What's Inside</h2>

            <p>
              React Native allows you to build native apps using JavaScript and
              the React framework. That means that by using the same mental
              models and similar tools we can build for the web, iOS, and
              Android while ensuring each platform has a unique and high quality
              experience. That's a big win!
            </p>
            <p>
              This course will progressively teach you how to build apps with
              React Native through 10 high quality examples.
            </p>
            <p>
              <b>Bonus:</b> Each example has a series of additional challenges
              allowing you to make each app your own and add high quality
              examples to your portfolio.
            </p>
          </div>
          <div className="column">
            <Img fluid={file.childImageSharp.fluid} alt="Lesson" />
          </div>
        </div>
      </div>
    </section>
  )
}
