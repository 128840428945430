import React from "react"
import { useStaticQuery, graphql } from "gatsby"

export default () => {
  const res = useStaticQuery(
    graphql`
      query {
        allFaqYaml {
          nodes {
            q
            a
          }
        }
      }
    `
  )

  const faq = res.allFaqYaml.nodes
  return (
    <section className="section">
      <div className="container">
        <div className="columns">
          <div className="column is-one-fifth" />
          <div className="column content">
            <h2 className="title is-2">Frequently Asked Questions</h2>
            {faq.map(q => (
              <React.Fragment>
                <h3 className="title is-4">{q.q}</h3>
                <p>{q.a}</p>
              </React.Fragment>
            ))}
          </div>
          <div className="column is-one-fifth" />
        </div>
      </div>
    </section>
  )
}
