import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

const getRandom = (all, maxLength) => {
  const remaining = [...all]
  const final = []
  for (let i = 0; i < maxLength; i += 1) {
    const index = Math.floor(Math.random() * remaining.length)
    final.push(remaining[index])
    remaining.splice(index, 1)
  }

  return final
}

const Testimonial = styled.div`
  background: transparent;
  quotes: "“" "”" "“" "”";

  &::before {
    content: open-quote;
    color: rgba(0, 0, 0, 0.05);
    font-size: 10rem;
    z-index: -1;
    position: absolute;
    top: -2.75rem;
    left: -1rem;
  }
`

export default () => {
  const res = useStaticQuery(
    graphql`
      query Testimonials {
        allTestimonialsYaml {
          nodes {
            content
            author
          }
        }
      }
    `
  )
  const testimonials = getRandom(res.allTestimonialsYaml.nodes, 5)

  return (
    <section className="section">
      <div className="container">
        <div className="has-text-centered content">
          <h3 className="title is-2">Testimonials</h3>
          <p className="subtitle is-4">Hear from past students</p>
        </div>

        {testimonials.map(testimonial => {
          return (
            <div className="columns is-vcentered">
              <div className="column is-one-fifth" />
              <div className="column">
                <Testimonial className="notification">
                  <p>{testimonial.content}</p>
                  <p>
                    <b>- {testimonial.author}</b>
                  </p>
                </Testimonial>
              </div>
              <div className="column is-one-fifth" />
            </div>
          )
        })}
      </div>
    </section>
  )
}
