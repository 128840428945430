import React from "react"
import YouTubePlayer from "react-player/lib/players/YouTube"
import styled from "styled-components"

const RespWrap = styled.div`
  position: relative;
  padding-bottom: 56.25%; /*16:9*/
  overflow: hidden;
`

const RespPlayer = styled(YouTubePlayer)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
`

const Button = styled.a`
  margin-bottom: 1rem;
`

const Header = styled.h1`
  /* font-weight: 700 !important; */
`

export default () => (
  <section className="hero is-info is-medium">
    <div className="hero-body">
      <div className="container">
        <div className="columns is-vcentered">
          <div className="column">
            <RespWrap>
              <RespPlayer
                url="https://www.youtube.com/watch?v=NqAGlqW88Ao"
                width="100%"
                height="100%"
                config={{
                  youtube: {
                    // color: "white",
                    controls: 0,
                    modestbranding: 1,
                    rel: 0,
                  },
                }}
              />
            </RespWrap>
          </div>
          <div className="column content">
            <Header className="title is-1">React Native by Example</Header>
            <p className="subtitle">
              Practice makes perfect. We've all heard that statement before.
              Why? Because it's true. The only way to get better at something is
              by doing.
            </p>

            <p className="subtitle">
              In React Native by Example we build 10 progressively more complex
              apps with React Native so you have the experience to tackle
              whatever the world throws at you.
            </p>

            <Button className="button is-primary is-medium" href="#buy">
              <strong>Join the Course</strong>
            </Button>

            <p>Join over 17,000 other developers in learning React Native.</p>
          </div>
        </div>
      </div>
    </div>
  </section>
)
