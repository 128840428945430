import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

export default () => {
  const { file } = useStaticQuery(
    graphql`
      query AuthorImage {
        file(relativePath: { eq: "spencer.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 300, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  return (
    <section className="section">
      <div className="container content">
        <div className="columns is-vcentered">
          <div className="column" />
          <div className="column is-3">
            <figure className="image">
              <Img
                className="is-rounded"
                fluid={file.childImageSharp.fluid}
                alt="Author"
              />
            </figure>
          </div>
          <div className="column is-5">
            <h3 className="title is-2">Spencer Carli</h3>
            <p className="subtitle is-3">About the Instructor</p>
            <p>
              Meet Spencer Carli. Spencer has been using React Native full-time
              since it was first open sourced in 2015. He's taught over 17,000
              students how to learn React Native through his courses and has
              reached tens of thousands through his dozens of React Native
              tutorials - if you've looked into React Native it's likely you've
              seen one (or many) of his tutorials!
            </p>
            <p>
              Beyond development Spencer is a cat dad (x3), loves pizza, and
              lives outside of Nashville Tennessee where he's a volunteer fire
              fighter.
            </p>
          </div>
          <div className="column" />
        </div>
      </div>
    </section>
  )
}
