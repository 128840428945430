import React from "react"

import { GlobalStyle } from "../styles"
import SEO from "../components/seo"
import Container from "../components/Container"
import CountDown from "../components/CountDown"

import Nav from "../segments/Nav"
import Header from "../segments/Header"
import Overview from "../segments/Overview"
import Examples from "../segments/Examples"
import Testimonials from "../segments/Testimonials"
import Buy from "../segments/Buy"
// import FreeSignup from "../segments/FreeSignup"
import AuthorAbout from "../segments/AuthorAbout"
import FAQ from "../segments/FAQ"
import Footer from "../segments/Footer"

const IndexPage = () => (
  <React.Fragment>
    <GlobalStyle />
    <SEO title="Learn React Native by Example | Comprehensive React Native Course" />
    <Header />
    <Nav />

    <Container>
      <CountDown
        endTime="2019-12-02T05:55:00.000Z"
        code="2019TURKEYDAYRNbE"
        promo="Get 40% off for a limited time!"
      />

      <Overview id="included" />
      <Examples />
      <Testimonials />
      <Buy id="buy" />
      {/* <FreeSignup /> */}
      <AuthorAbout id="instructor" />
      <FAQ id="faq" />
      <Footer />
    </Container>
  </React.Fragment>
)

export default IndexPage
