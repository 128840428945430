import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"

const useInterval = (callback, delay) => {
  const savedCallback = useRef()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

const getTimeRemaining = endtime => {
  const t = Date.parse(endtime) - Date.parse(new Date())
  const seconds = Math.floor((t / 1000) % 60)
  const minutes = Math.floor((t / 1000 / 60) % 60)
  const hours = Math.floor((t / (1000 * 60 * 60)) % 24)
  const days = Math.floor(t / (1000 * 60 * 60 * 24))

  return {
    total: t,
    days,
    hours,
    minutes,
    seconds,
    expired: t < 0,
  }
}

const useCountDown = endtime => {
  const [remaining, setRemaining] = useState(getTimeRemaining(endtime))

  useInterval(() => {
    const newRemaining = getTimeRemaining(endtime)
    setRemaining(newRemaining)
  }, 1000)

  return remaining
}

const ClockRow = styled.div`
  display: flex;
  flex-direction: row;
  text-align: center;
  font-size: 30px;
  justify-content: center;
`

const TimeItem = styled.div`
  padding: 10px;
  border-radius: 3px;
  display: inline-block;
`

const Remaining = styled.p`
  color: #fff;
  padding: 15px;
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.3);
  display: inline-block;
`

const SmallText = styled.p`
  padding-top: 5px;
  font-size: 16px;
`

const CountDown = ({ endTime, code, promo, children }) => {
  const { days, hours, minutes, seconds, expired } = useCountDown(
    new Date(endTime)
  )

  if (expired) {
    return children || null
  }

  return (
    <section className="section">
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-half has-text-centered">
            <h3 className="title is-3">{promo}</h3>
            <p className="subtitle">
              Use code <em>{code}</em> at checkout.
            </p>

            <ClockRow>
              <TimeItem>
                <Remaining>{days}</Remaining>
                <SmallText>Days</SmallText>
              </TimeItem>
              <TimeItem>
                <Remaining>{hours}</Remaining>
                <SmallText>Hours</SmallText>
              </TimeItem>
              <TimeItem>
                <Remaining>{minutes}</Remaining>
                <SmallText>Minutes</SmallText>
              </TimeItem>
              <TimeItem>
                <Remaining>{seconds}</Remaining>
                <SmallText>Seconds</SmallText>
              </TimeItem>
            </ClockRow>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CountDown
